export default {
  main_manager: [
    {
      action: ["list"],
      subject: ["Board"]
    },
    {
      action: ["add", "list", "delete"],
      subject: ["Documents"]
    },
    {
      action: ["create", "delete", "edit"],
      subject: ["Board:column"]
    },
    {
      action: ["create", "delete", "edit", "changeManager", "deleteContacts"],
      subject: ["Board:card"]
    }
  ],
  admin: [
    {
      action: ["list"],
      subject: ["Board"]
    },
    {
      action: ["add", "list", "delete"],
      subject: ["Documents"]
    },
    {
      action: ["create", "delete", "edit"],
      subject: ["Board:column"]
    },
    {
      action: ["create", "delete", "edit", "changeManager", "editContacts"],
      subject: ["Board:card"]
    }
  ],
  manager: []
};
